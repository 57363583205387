body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: rgb(240, 232, 232);
}

#chatbutton {
    /*background-color: blue;*/
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 10px;
    left: 10px;
}

#chatbutton:hover {
    cursor: pointer;
}

#chatwindow {
    /* width: 243px; */
    /* border: 1px solid blue; */
    position: fixed;
    bottom: 50px;
    left: 50px; 
}

.hidden {
    display: none;
}

.shown {
    display: block;
}