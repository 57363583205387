@charset "utf-8";

$purple: #8A4D76;

$blue: #72d0eb;

// Update Bulma's global variables
$primary: $blue;

$dimensions: 16 24 32 48 64 96 128 256;

@import "~bulma/bulma";

